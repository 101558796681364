const alerts = [
    {
        name: "Ракета",
        emoji: "🚀",
        description: "Загроза ракетного удару",
    },
    {
        name: "БПЛА",
        emoji: "🛸",
        description: "Загроза удару безпілотником",
    },
    {
        name: "КАБ",
        emoji: "✈️",
        description: "Загроза удару керованими авіаційними бомбами",
    },
    {
        name: "Балістика",
        emoji: "↓",
        description: "Загроза удару балістичними ракетами",
    },
    {
        name: "Невідомо",
        emoji: "❓",
        description: "Невідома загроза",
    },
    {
        name: "Міг-31к",
        emoji: "🛩️",
        description: "Зліт літака Міг-31К",
    },
    {
        name: "Ту-95мс",
        emoji: "🛫",
        description: "Зліт літака Ту-95МС",
    },
    {
        name: "Ту-22м3",
        emoji: "🛫",
        description: "Зліт літака Ту-22М3",
    },
    {
        name: "Ту-160",
        emoji: "🛫",
        description: "Зліт літака Ту-160",
    }
]


export const getAlertDescription = (alertName: string) => {
    const alert = alerts.find(alert => alert.name === alertName);
    return alert ? alert.description : "";
}

export const getAlertEmoji = (alertName: string) => {
    const alert = alerts.find(alert => alert.name === alertName);
    return alert ? alert.emoji : "";
}
