import React, {useEffect, useState} from 'react';
import Map from './Map';
import {Box, Card, Grid, Switch, Typography} from '@mui/material';
import Alarms from "./data/Alarms";
import AlertsList from "./AlertsList";
import SourcesList from "./SourcesList";

function App() {
    const [alarms, setAlarms] = useState<Alarms>({"Україна": {}, "росія": {}});

    useEffect(() => {
        const fetchData = () => {
            fetch('https://rocket-bot-bucket.s3.eu-west-1.amazonaws.com/alarms-v2.json')
                .then((response) => response.json())
                .then((data) => setAlarms(data))
                .catch((error) => console.log(error));
        };

        fetchData(); // Fetch data immediately

        const interval = setInterval(fetchData, 10000); // Fetch data every 10 seconds

        return () => {
            clearInterval(interval); // Clean up the interval on component unmount
        };
    }, []);

    return (
        <div className="App">
            <Grid container sx={{ background: '#282c34', minHeight: '100vh' }}>
                <Grid item xs={12} md={8} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Map ukrainianAlarms={alarms["Україна"]} russianAlarms={alarms["росія"]} />
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="center" justifyContent="center">
                    <Box display="flex" flexDirection="column" alignItems="right" justifyContent="center">
                    <Card sx={{ margin: 2, padding: 2 }}>
                        <Typography>Дослідна версія карти, що відображає повітряні загрози в Україні отримані з телеграм-каналів:</Typography>
                        <SourcesList />
                    </Card>
	                    <AlertsList ukrainianAlarms={alarms["Україна"]} russianAlarms={alarms["росія"]} />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

export default App;
