import {Card, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {getAlertDescription, getAlertEmoji} from "./alerts";
import React from "react";
import Alarms from "./data/Alarms";

const AlertsList = (props: {ukrainianAlarms: Alarms["Україна"], russianAlarms: Alarms["росія"]}) => {
    const {ukrainianAlarms, russianAlarms} = props;

    const alarms = [...Object.values(ukrainianAlarms), ...Object.values(russianAlarms)];

    const isAnyAlert = alarms.some(region => region.alerts.length > 0);

    return <Card sx={{ margin: 2, padding: 2 }}>
        <Typography>{"Список регіонів, де існує небезпека:"}</Typography>
        {!isAnyAlert && <Typography>Наразі немає сповіщень</Typography>}
        {isAnyAlert && <List sx={{width: "100%"}}>
            {alarms.map(({name, alerts}) => (
                alerts.map(alert => (
                    <ListItem key={name + alert}>
                        <ListItemIcon>
                            {getAlertEmoji(alert.type)}
                        </ListItemIcon>
                        <ListItemText>
                            {name} - {getAlertDescription(alert.type)}
                        </ListItemText>
                    </ListItem>
                ))
            ))}
        </List>}
    </Card>
}

export default AlertsList;
