import React, { useRef } from 'react'
import { getAlertEmoji } from './alerts';
import regions from "./regions";
import Alarms from "./data/Alarms";

const russianCoordinates = {x: 520, y: 50}

const FlyingStuff = (props: { ukrainianAlarms: Alarms["Україна"], russianAlarms: Alarms["росія"] }) => {
    const textRefs = useRef<Array<SVGTextElement | null>>([]);

    const alarms = [
        ...Object.values(props.ukrainianAlarms),
        ...Object.values(props.russianAlarms)
    ]
    console.log(alarms);

    return (
        <>
            {alarms.map(({name, alerts}) => (
                alerts.map((alert, index) => {

                    const regionData = regions.find((regionData) => regionData.name === name) || russianCoordinates;

                    // Randomize coordinates a bit +- 10
                    regionData.x += Math.floor(Math.random() * 10) - 5;
                    regionData.y += Math.floor(Math.random() * 10) - 5;

                    return (
                        <text
                            key={index}
                            ref={(ref) => {
                                textRefs.current[index] = ref;
                            }}
                            x={regionData.x}
                            y={regionData.y}
                        >
                            {getAlertEmoji(alert.type)}
                        </text>
                    );
                })
            ))}
        </>
    );
};

export default FlyingStuff;
