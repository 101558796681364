import React from 'react';
import "./Map.css"
import regions from "./regions";
import {green, red} from "./styles";
import FlyingStuff from "./FlyingStuff";
import Alarms, {AlertType} from "./data/Alarms";

const Map = (props: {ukrainianAlarms: Alarms["Україна"], russianAlarms: Alarms["росія"]}) => {
    const { ukrainianAlarms, russianAlarms } = props;

    const isAlarm = (region: string) => {
        const regionAlarms = ukrainianAlarms[region];

        return regionAlarms?.is_alarm || false;
    }

    const getAlerts = (region: string) => {
        const regionAlarms = ukrainianAlarms[region];

        return {
            rocket: regionAlarms?.alerts?.some((alert) => alert.type === AlertType.ROCKET),
            drone: regionAlarms?.alerts?.some((alert) => alert.type === AlertType.DRONE),
            unknown: regionAlarms?.alerts?.some((alert) => alert.type === AlertType.UNKNOWN),
            gap: regionAlarms?.alerts?.some((alert) => alert.type === AlertType.DAB),
            ballistics: regionAlarms?.alerts?.some((alert) => alert.type === AlertType.BALLISTICS),
        }
    }

    return (
        <div className="Map">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 612.47321 408.0199">

            {regions.map((region) => {
                return (<g style={{fill: isAlarm(region.name) ? red : green}}>{region.component}</g>)
            })}
            <FlyingStuff ukrainianAlarms={ukrainianAlarms} russianAlarms={russianAlarms}/>
        </svg>
        </div>

)
}

export default Map;
