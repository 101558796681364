import Cherkaska from "./Cherkaska";
import React from "react";
import Zhytomyrska from "./Zhytomyrska";
import Chernihivska from "./Chernihivska";
import Chernivetska from "./Chernivetska";
import Crimea from "./Crimea";
import Dnipropetrovska from "./Dnipropetrovska";
import Donetska from "./Donetska";
import IvanoFrankivska from "./IvanoFrankivska";
import Kharkivska from "./Kharkivska";
import Khersonska from "./Khersonska";
import Khmelnytska from "./Khmelnytska";
import Kirovohradska from "./Kirovohradska";
import Kyiv from "./Kyiv";
import Kyivska from "./Kyivska";
import Luhanska from "./Luhanska";
import Lvivska from "./Lvivska";
import Mykolaivska from "./Mykolaivska";
import Odeska from "./Odeska";
import Poltavska from "./Poltavska";
import Rivnenska from "./Rivnenska";
import Sevastopol from "./Sevastopol";
import Sumska from "./Sumska";
import Ternopilska from "./Ternopilska";
import Vinnytska from "./Vinnytska";
import Volynska from "./Volynska";
import Zakarpatska from "./Zakarpatska";
import Zaporizka from "./Zaporizka";

export enum Region {
    Cherkaska = "Черкаська область",
    Chernihivska = "Чернігівська область",
    Chernivetska = "Чернівецька область",
    Crimea = "Автономна Республіка Крим",
    Dnipropetrovska = "Дніпропетровська область",
    Donetska = "Донецька область",
    IvanoFrankivska = "Івано-Франківська область",
    Kharkivska = "Харківська область",
    Khersonska = "Херсонська область",
    Khmelnytska = "Хмельницька область",
    Kirovohradska = "Кіровоградська область",
    Kyiv = "м. Київ",
    Kyivska = "Київська область",
    Luhanska = "Луганська область",
    Lvivska = "Львівська область",
    Mykolaivska = "Миколаївська область",
    Odeska = "Одеська область",
    Poltavska = "Полтавська область",
    Rivnenska = "Рівненська область",
    Sevastopol = "м. Севастополь",
    Sumska = "Сумська область",
    Ternopilska = "Тернопільська область",
    Vinnytska = "Вінницька область",
    Volynska = "Волинська область",
    Zakarpatska = "Закарпатська область",
    Zaporizka = "Запорізька область",
    Zhytomyrska = "Житомирська область"
}

export type RegionData = {
    name: Region,
    x: number,
    y: number,
    component: React.ReactNode
}

const regions: RegionData[] = [
    {
        name: Region.Cherkaska,
        x: 305,
        y: 172,
        component: <Cherkaska />,
    },
    {
        name: Region.Chernihivska,
        x: 325,
        y: 70,
        component: <Chernihivska />,
    },
    {
        name: Region.Chernivetska,
        x: 120,
        y: 230,
        component: <Chernivetska />,
    },
    {
        name: Region.Crimea,
        x: 400,
        y: 380,
        component: <Crimea />,
    },
    {
        name: Region.Dnipropetrovska,
        x: 410,
        y: 220,
        component: <Dnipropetrovska />,
    },
    {
        name: Region.Donetska,
        x: 520,
        y: 230,
        component: <Donetska />,
    },
    {
        name: Region.IvanoFrankivska,
        x: 75,
        y: 210,
        component: <IvanoFrankivska />,
    },
    {
        name: Region.Kharkivska,
        x: 480,
        y: 150,
        component: <Kharkivska />,
    },
    {
        name: Region.Khersonska,
        x: 380,
        y: 300,
        component: <Khersonska />,
    },
    {
        name: Region.Khmelnytska,
        x: 150,
        y: 170,
        component: <Khmelnytska />,
    },
    {
        name: Region.Kirovohradska,
        x: 325,
        y: 212,
        component: <Kirovohradska />,
    },
    {
        name: Region.Kyiv,
        x: 275,
        y: 111,
        component: <Kyiv />,
    },
    {
        name: Region.Kyivska,
        x: 275,
        y: 141,
        component: <Kyivska />,
    },
    {
        name: Region.Luhanska,
        x: 558,
        y: 194,
        component: <Luhanska />,
    },
    {
        name: Region.Lvivska,
        x: 50,
        y: 150,
        component: <Lvivska />,
    },
    {
        name: Region.Mykolaivska,
        x: 320,
        y: 290,
        component: <Mykolaivska />,
    },
    {
        name: Region.Odeska,
        x: 275,
        y: 310,
        component: <Odeska />,
    },
    {
        name: Region.Poltavska,
        x: 400,
        y: 160,
        component: <Poltavska />,
    },
    {
        name: Region.Rivnenska,
        x: 130,
        y: 100,
        component: <Rivnenska />,
    },
    {
        name: Region.Sevastopol,
        x: 360,
        y: 400,
        component: <Sevastopol />,
    },
    {
        name: Region.Sumska,
        x: 410,
        y: 90,
        component: <Sumska />,
    },
    {
        name: Region.Ternopilska,
        x: 110,
        y: 170,
        component: <Ternopilska />,
    },
    {
        name: Region.Vinnytska,
        x: 210,
        y: 200,
        component: <Vinnytska />,
    },
    {
        name: Region.Volynska,
        x: 90,
        y: 90,
        component: <Volynska />,
    },
    {
        name: Region.Zakarpatska,
        x: 20,
        y: 220,
        component: <Zakarpatska />,
    },
    {
        name: Region.Zaporizka,
        x: 440,
        y: 260,
        component: <Zaporizka />,
    },
    {
        name: Region.Zhytomyrska,
        x: 200,
        y: 110,
        component: <Zhytomyrska />,
    }

];

export default regions;