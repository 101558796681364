import React from 'react';
import {List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';

const SourcesList = () => {
    return <List>
        <ListItem key={0}>
            <ListItemIcon>
                <TelegramIcon />
            </ListItemIcon>
            <ListItemText><a href="https://t.me/kpszsu">Повітряні Сили ЗС України</a></ListItemText>
        </ListItem>
        <ListItem key={1}>
            <ListItemIcon>
                <TelegramIcon />
            </ListItemIcon>
            <ListItemText><a href="https://t.me/operatyvnii">Оперативний інформ</a></ListItemText>
        </ListItem>
        <ListItem key={1}>
            <ListItemIcon>
                <TelegramIcon />
            </ListItemIcon>
            <ListItemText><a href="https://t.me/radar_ukr">Моніторинг військової активності</a></ListItemText>
        </ListItem>
    </List>

}

export default SourcesList;
